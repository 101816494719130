import moment from 'moment';
import axios from 'axios';
import Loader from "@/components/Loader/Loader.vue";
import buttonLoader from "@/components/Loader/buttonLoader.vue";
import GA4Accounts from "./GA4.json"
import { watch } from 'vue';

export default {
    name: "ConnectAccounts",
    components: {
        Loader,buttonLoader,
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment("2023-10-01").format("YYYY-MM-DD"),
                endDate: moment("2023-10-31").format("YYYY-MM-DD")
            },
            selectedCountry:'India',
            countryList:['India','UK'],
            manageButtonLoader:false,
            connectButtonLoader:false,
            selectedConnectPlatformName:false,
            amazonSetDefaultAccountLoader:false,
            topTabs:['All'],
            imageMapper:{
                "Amazon":{
                    imgName:'amazon-black',
                    connectMethod:'getURLforConnectAccount',
                    manageMethod:'getAmazonAccountsDetails'
                },
                "Flipkart":{
                    imgName:'Flipkart',
                    connectMethod:'getURLforConnectAccount',
                    manageMethod:'getAmazonAccountsDetails'
                },
                "Myntra":{
                    imgName:'Myntra',
                    connectMethod:'getURLforConnectAccount',
                    manageMethod:'getAmazonAccountsDetails'
                }, 
                "Shopify":{
                    imgName:'Shopify',
                    connectMethod:'getURLforConnectAccount',
                    manageMethod:'getAmazonAccountsDetails'
                },
                "Google Ads":{
                    imgName:'Google Ads',
                    connectMethod:'getURLforConnectGoogleAdsAccount',
                    manageMethod:'getGoogleAdsAcccountDetails'
                },
                "Shopify":{
                    imgName:'Shopify',
                    connectMethod:'getURLforConnectAccount',
                    manageMethod:'getAmazonAccountsDetails'
                },
                "Meta Ads":{
                    imgName:'meta',
                    connectMethod:'getURLforConnectAccount',
                    manageMethod:'getAmazonAccountsDetails'
                },
                "Amazon ADs":{
                    imgName:'Amazon ads',
                    connectMethod:'getURLforConnectGA4Account',
                    manageMethod:'getAmazonAccountsDetails'
                },
                "Google Analytics":{
                    imgName:'Google Analytics',
                    connectMethod:'getURLforConnectGA4Account',
                    manageMethod:'getGA4AccountsDetails'
                }
            },
            viewAllPlatformListData:'',
            viewUnModifiedPlatformListData:'',
            isManageAccount:false,
            selectedTab:'All',
            selectedPlatformDetails:'',
            connectAccountPopup:'',
            viewURLForConnectAccount:"",
            viewAmazonConnectedAccountData:'',
            viewUnModifiedConnectedAccountData:'',
            totalPage:'',
            selectedPage:1,
            GA4Data : GA4Accounts,
            shopifyStoreName:'weavr-test-store',
            isModalOpenforAddStore:'',
            errorMessage:''
        }
    },
    created() {
        this.data();
        this.initializeModal();

    },
    mounted() {
        this.initializeModal();
    },
    
    methods: {
        data(){
            this.getPlatformList()
        },

        backToPlatformListSection(){
            this.isManageAccount=false
        },

        changeTab(tabName){
            this.selectedTab = tabName
            if(tabName!='All'){
                this.viewAllPlatformListData = []
                this.viewUnModifiedPlatformListData.forEach(data=>{
                    if(tabName==data.platform_type){
                        this.viewAllPlatformListData.push(data);
                    }
                });
            }
            else{
                this.viewAllPlatformListData = this.viewUnModifiedPlatformListData;
            }
        },

        initializeModal() {
            const $shopifyStoreAdd = document.getElementById("add-shopify-modal");
            const shopifyStoreModalOptions = {
              placement: "center",
              backdrop: "dynamic",
              backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
              closable: true,
              onClose: () => {
               this.shopifyStoreName=''
              },
            };
            this.isModalOpenforAddStore = new Modal($shopifyStoreAdd, shopifyStoreModalOptions);
          },

        connectAccount(platform){
            this.selectedConnectPlatformName = platform
            this.commonMethodConnectAccount()
        },

        manageAccount(platform,row){
            this.getAccountDetails(true,row);
        },

        hideRuleModal(){
            this.shopifyStoreName = ''
            this.isModalOpenforAddStore.hide()
            this.loader = false
        },

        getPlatformList(){
            this.loader = true
            this.viewAllPlatformListData =''
            this.viewUnModifiedPlatformListData =''
            const url="/api/v1/PlugNPlayAccounts"
            axios.get(url).then(res=>{
                this.viewUnModifiedPlatformListData = res.data.data
                this.viewAllPlatformListData = res.data.data
                this.processTabList();
                this.loader= false
            })
        },

        openConnectAccountModal(){
            this.connectAccountPopup.show()
        },
        commonMethodConnectAccount(){
            const URLCollection = {
                "Amazon":'/api/amazon/login',
                "Flipkart":'',
                "Google Analytics":"api/v1/ga4login",
                "Google Ads":"/api/v1/GoogleAdsLogin",
                "Meta Ads": "/api/fb/login",
                "Shopify":"/api/shopify/login?shop="
            }
            this.connectButtonLoader = true
            this.viewURLForConnectAccount = ''
            if(!URLCollection[this.selectedConnectPlatformName]){
                this.loader = false
                return false
            }
            let url = URLCollection[this.selectedConnectPlatformName]

            if(this.selectedConnectPlatformName=='Shopify'){
                this.isModalOpenforAddStore.show()
                this.connectButtonLoader = false
                this.loader = false
                return false
                // url = url+this.shopifyStoreName
            }

            axios.get(url).then((res)=>{
                this.viewURLForConnectAccount = res.data.data
                this.connectButtonLoader = false
                let feature = 'popup,height=600,width=800,top=200,left=400,toolbar=0,Location=0,Directories=0,Status=0,menubar=0,Scrollbars=0,Resizable=0'
                const popupWindow = window.open(this.viewURLForConnectAccount, null, feature);
                const popupChecker = setInterval(function () {
                    if (popupWindow.closed) {
                      clearInterval(popupChecker); // Stop the interval once popup is closed
                      location.reload(); // Reload the parent page
                    }
                  }, 100);
            })
        },

        connectShopifyAccount(){
            this.connconnectButtonLoader = true
            const url = "/api/shopify/login?shop="+this.shopifyStoreName
            axios.get(url).then((res)=>{
                this.connectButtonLoader= false
                this.viewURLForConnectAccount = res.data.data
                this.connectButtonLoader = false
                let feature = 'popup,height=600,width=800,top=200,left=400,toolbar=0,Location=0,Directories=0,Status=0,menubar=0,Scrollbars=0,Resizable=0'
                const popupWindow = window.open(this.viewURLForConnectAccount, null, feature);
                const popupChecker = setInterval(function () {
                    if (popupWindow.closed) {
                      clearInterval(popupChecker); // Stop the interval once popup is closed
                      location.reload(); // Reload the parent page
                    }
                  }, 100);
            }).catch((err)=>{
                this.errorMessage = err.response.data.data
                // console.log("check the data",err.response.data.data)
            })
        },
        getAccountDetails(isManage,selectedPlatformDetails){
            const URLCollection = {
                "Amazon":'/api/amazon/AmazonGetAccounts',
                "Flipkart":'',
                "Google Analytics":"/api/v1/ga4allaccounts",
                "Google Ads":"/api/v1/GoogleAdsGetAccounts",
                "Meta Ads": "/api/fb/getallaccounts",
                "Shopify":"/api/"
            }

            if(isManage){
                this.manageButtonLoader = true
                this.selectedPlatformDetails='',
                this.selectedPlatformDetails = selectedPlatformDetails
            }
            this.viewAmazonConnectedAccountData = ''
            if(!URLCollection[this.selectedPlatformDetails.platform]){
                this.loader = false
                return false
            }
            if(this.selectedPlatformDetails.platform=='Shopify'){
                console.log("check the data");
                this.viewUnModifiedConnectedAccountData = ''
                this.isManageAccount= true
                if(isManage) this.manageButtonLoader = false
                return false
            }
            const url = URLCollection[this.selectedPlatformDetails.platform]
            axios.get(url).then((res)=>{
                this.viewUnModifiedConnectedAccountData = res.data.data
                this.totalPage= Math.round(this.viewUnModifiedConnectedAccountData.length/10)
                this.procesdAllAccountsPagination(this.selectedPage);
                this.isManageAccount= true
                if(isManage) this.manageButtonLoader = false
                else{
                    this.loader = false
                } 
            }).catch(err=>{
                console.log("error AmazonGetAccounts")
            })
        },

        disconnectAccount(AccountData){
            this.manageButtonLoader = true
            const url = "api/v1/PlugNPlayAccounts"
            const payload ={
                "platform_alias": AccountData.platform_alias,
                "platform_id": AccountData.platform_id         
            }
            axios.delete(url,{data:payload}).then(res=>{
                this.$nextTick(() => {
                    this.changeDataAfterConnectAndDisconnect();
                })
                this.getPlatformList()
                this.manageButtonLoader=false
                this.isManageAccount=false

            }).catch(err=>{
                console.log("erro delete")
            })
        },
        
        selectDefaultAccount(accountId){
            const URLCollection = {
                "Amazon":'/api/amazon/AmazonSetDefaultAccount?account_id=',
                "Flipkart":'',
                "Google Analytics":"/api/v1/ga4setdefaultaccount?account_id=",
                "Google Ads": "/api/v1/GooogleAdsSetDefaultAccount?account_id=",
                 "Meta Ads": "/api/fb/setdefaultaccount?account_id="
            }

            this.loader= true
            
            if(!URLCollection[this.selectedPlatformDetails.platform]){
                this.loader = false
                return false
            }
            const url = URLCollection[this.selectedPlatformDetails.platform]+accountId
            axios.get(url).then(res=>{
                this.changeDataAfterConnectAndDisconnect()
                this.getAccountDetails(false,'')
                this.loader= false
            })
        },

        changeDataAfterConnectAndDisconnect() {
            const id = JSON.parse(localStorage.userData).data.client_id
            let url = "/api/users/switch_clients/" + id + "/"
            axios.post(url).then((res) => {
                console.log('success')
                axios.defaults.headers.common['token'] = res.data.data.token
                localStorage.userData = JSON.stringify(res.data)
                localStorage.removeItem('selectedMenu')
                this.$nextTick(() => {
                    this.$store.dispatch('setUser')
                    this.$store.dispatch('setMenu')
                })
            }).catch(err => console.log(err))
        },

        processTabList(){
            this.topTabs = ['All']
            this.viewAllPlatformListData.forEach(element => {
                if(!this.topTabs.includes(element.platform_type))
                    this.topTabs.push(element.platform_type);
            });
        },
        // pagination section 

        procesdAllAccountsPagination(selectedPageNumber){
            this.loader = true
            this.selectedPage = selectedPageNumber
            this.viewAmazonConnectedAccountData = ''
            this.viewAmazonConnectedAccountData =  this.viewUnModifiedConnectedAccountData.slice((this.selectedPage-1)*10,this.selectedPage*10)
            this.loader = false
        }
    }
}