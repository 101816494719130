import axios from 'axios';
import lineChart from '@/components/charts/lineChart.vue';
import Loader from '@/components/Loader/Loader.vue';
export default {
    name:'Summary',
    components: {
        Loader, lineChart
    },
    data(){
        return {
            viewCampListData:[],
            selectedCampaignList:'',
            viewTopMetricData:{},
            viewGraphData:'',
            viewProccedGraphData:'',
            platform: this.$route.params.platform,
            loader : false,
            dateRange: {
                startDate: moment().subtract(4, 'week').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
        }
    },

    created(){
        this.getDataForAllPlatform();
    },
    methods: {
        getDataForAllPlatform(){
            this.viewGraphData = '',
            this.viewTopMetricData = {}
            this.loader = true
            const payload = {
                "start_date": moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                "end_date": moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                "platform": this.platform
            }
            const url = "/api/v1/PlugNPlayAccountsData"
            axios.post(url,payload).then(res=>{
                this.loader = false
                if(res){
                    this.viewTopMetricData = this.platform=='Shopify'?res.data.orders:res.data.campaigns,
                    this.viewGraphData = res.data.graph,
                    this.getCampaignData();
                    this.processGraphData();
                }
            
            }).catch((err)=>{
                console.log("Err Plugin PlayAccountsData")
                this.loader = false
            })
        },

        changePlatform(){
            processGraphData();
        },
        getCampaignData(){
            this.viewCampListData = Object.keys(this.viewTopMetricData)
            this.selectedCampaignList= this.viewCampListData[0]
        },

        processGraphData(){
            let finalTemp = []
            Object.entries(this.viewGraphData[this.selectedCampaignList]).forEach(([key,data])=>{
               let temp = {
                name:key,
                xAxis:[],
                seriesData:[{
                    name:key,
                    data:[]
                }]
               }
               Object.entries(data).forEach(([date,value])=>{
                     temp.xAxis.push(date)
                     temp.seriesData[0].data.push(value);
               })
               finalTemp.push(temp);
            })
            this.viewProccedGraphData = finalTemp;
        }
    },
}